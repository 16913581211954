<template>
  <div class="index">
    <m-header ref="header" />
    <router-view />
    <m-tail />
    <div class="index-position">
      <el-popover placement="left" trigger="hover">
        <div class="index-position-img">
          <template v-if="localStorageType==1">
            <img src="/imgs/er-wei-ma02-his.jpg" alt=""/>
          </template>
          <template v-else-if="localStorageType==2">
            <img src="/imgs/er-wei-ma02-com.jpg" alt=""/>
          </template>
        </div>
        <div slot="reference" class="index-position-message">联系我们</div>
      </el-popover>
      <div @click="onTop" class="index-position-top">
        <el-icon class="el-icon-upload2" />
      </div>
    </div>
  </div>
</template>

<script>
import {getUserTokenUserInfo, getXinzhiUserInfo} from "@/api/product";
import MHeader from "@/components/header/index.vue";
import MTail from "@/components/tail/index.vue";
import { isMobile } from "@/util/common";
import {mapMutations} from "vuex";

export default {
  data() {
    return {
      localStorageType: localStorage.getItem("type") || 1,
    };
  },

  components: {
    MHeader,
    MTail,
  },

  methods: {
    ...mapMutations("user", ["setToken", "updateUserInfo"]),
    onTop() {
      window.scrollTo(0, 0);
    },


  },
  created() {
    console.log("homepage created");
    const { name } = this.$route;
    const {query={}}=this.$route
    console.log("query=",query)
    const {code}=query
    const {token}=query

    // console.log(window.location.search);
    // let path = window.location.search.split("?");
    // console.log(path);
    //
    // if (path.length > 1) {
    //  var  code = window.location.search.split("?")[1].split("=")[1];
    //  console.log(code)
    // }
    console.log(code)
    if (isMobile() && !name.includes("mobile")) {
      this.$router.push({
        name: `mobile-${name}`,
      });
    }
    if(code){
      console.log("get data from xinzhi");
      getXinzhiUserInfo(code).then((res) => {
        if (res.code === 200) {
          var result = res.data;
          console.log(result);
          console.log("token:"+result.map.access_token)
          this.setToken(result.map.access_token);
          localStorage.setItem("token", result.map.access_token);
          console.log("info:"+JSON.stringify(result.userInfoDto));
          this.updateUserInfo(result.userInfoDto);
          localStorage.setItem("userInfo", JSON.stringify(result.userInfoDto));

        }
      })
    }
    if(token){
      getUserTokenUserInfo(token).then((res) => {
        if (res.code === 200) {
          var result = res.data;
          console.log(result);
          console.log("token:"+result.map.access_token)
          this.setToken(result.map.access_token);
          localStorage.setItem("token", result.map.access_token);
          console.log("info:"+JSON.stringify(result.userInfoDto));
          this.updateUserInfo(result.userInfoDto);
          localStorage.setItem("userInfo", JSON.stringify(result.userInfoDto));
        }
      })
    }


  },
};
</script>

<style>
.el-popover {
  min-width: 100px;
}
</style>
<style scoped lang="scss">
.index {
  background-color: white;

  &-position {
    position: fixed;
    right: 40px;
    z-index: 100000;
    top: 50%;
    transform: translate(-50%, 0);

    &-message {
      cursor: pointer;
      box-sizing: content-box;
      width: 20px;
      padding: 10px;
      background-color: #5893fe;
      word-wrap: break-word;
      font-size: 16px;
      text-align: center;
      color: white;
      border-radius: 6px;
    }

    &-img {
      width: 100px;
      height: 100px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-top {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: content-box;
      width: 20px;
      height: 20px;
      padding: 10px;
      border: 1px solid #5893fe;
      color: #5893fe;
      background-color: white;
      font-size: 24px;
      margin-top: 24px;
    }
  }
}
</style>
