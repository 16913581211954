<template>
  <div class="header">
    <div class="header-logo">
      <template v-if="localStorageType==1">
        <img src="@/assets/kyyl.png" alt="开源医疗" />
      </template>
      <template v-else-if="localStorageType==2">
        <img src="@/assets/kyqy.png" alt="开源企业" />
      </template>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <el-icon class="el-icon-arrow-down" />
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click.native="onSelect(1)"
              >开源医疗</el-dropdown-item
            >
            <el-dropdown-item @click.native="onSelect(2)"
              >开源企业</el-dropdown-item
            >
             <!-- 判断本地localStorage.getItem("type")的值-->
            <!-- <template v-if="localStorageType==1">
              <el-dropdown-item>1</el-dropdown-item>
            </template>
            <template v-else-if="localStorageType==2">
              <el-dropdown-item>2</el-dropdown-item>
            </template> -->

          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div class="header-content">
      <el-button
        :type="$route.name === 'home' ? 'primary' : ''"
        @click="onClick('home')"
      >
        首页
      </el-button>
      <el-button style="margin-left: 0px" @click="onAnchor('production')">产品与服务</el-button>
      <el-button style="margin-left: 0px" @click="onAnchor('case')">行业案例</el-button>
      <el-button style="margin-left: 0px"
        :type="$route.name.includes('community') ? 'primary' : ''"
        @click="onClick('community')"
      >
        开源社区
      </el-button>
      <el-button style="margin-left: 0px" @click="onAnchor('news')">行业资讯</el-button>
      <el-button style="margin-left: 0px"
          :type="$route.name.includes('cooperation') ? 'primary' : ''"
          @click="onClick('cooperation')"
      >
        合作方式
      </el-button>
      <el-button style="margin-left: 0px" @click="onAnchor('tail')">关于我们</el-button>
    </div>
    <div class="header-op">
      <el-button v-if="!getUserInfo.userName" color="#333" @click="isShowLogin">
        登录
      </el-button>
      <el-dropdown v-if="getUserInfo && getUserInfo.userName">
        <span class="el-dropdown-link"> 欢迎, {{ getUserInfo.userName }}</span>
        <template #dropdown>
          <el-dropdown-menu>

            <el-dropdown-item @click.native="jumpPage('account')" v-if="getUserInfo.role=='superAdmin'">
              <i class="iconfont-new icon-zhanghu" />账户中心
            </el-dropdown-item>
            <el-dropdown-item @click.native="jumpPage('account')" v-if="getUserInfo.role=='user'||getUserInfo.role=='admin'">
              <i class="iconfont-new icon-zhanghu" />账户中心
            </el-dropdown-item>
            <el-dropdown-item @click.native="openAdmin()" v-if="getUserInfo.role=='superAdmin'||getUserInfo.role=='admin'">
              <i class="iconfont-new icon-zixun" />开源社区管理
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-button v-if="getUserInfo.userName" @click="loginOut" type="danger">
        退出
      </el-button>
      <el-button type="primary" @click="onAnchor('tail')">联系我们</el-button>
    </div>
    <login-model ref="loginModel" @loginSuccess="updateUserInfo"></login-model>
  </div>
</template>

<script>
import LoginModel from "@/components/loginModel.vue";
import { mapGetters, mapMutations } from "vuex";
import { MessageBox } from "element-ui";
import { loginOut } from "@/api/login";
//import community from "@/pages/new/community/index.vue";

export default {
  components: { LoginModel },
  data() {
    return {
      localStorageType: localStorage.getItem("type") || 2,
    };
  },
  methods: {
    ...mapMutations("user", ["setToken", "updateUserInfo"]),

    onClick(name) {
      this.$router.push({
        name,
      });
    },

    isShowLogin() {
      this.$refs.loginModel.showModel();
      this.$store.commit("setIsShow", 1);
    },

    loginOut() {
      let redirectedFrom = this.$route.path;
      MessageBox.confirm("您是否要退出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          loginOut().then((res) => {
            if (res.code === 200) {
              localStorage.clear();
              this.setToken("");
              this.updateUserInfo("");
              if (redirectedFrom.includes("/account")) {
                this.$router.push({ name: "home" });
              }

              this.$message({
                type: "success",
                message: "退出成功",
              });
            } else {
              this.$message.error("退出失败");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消退出",
          });
        });
    },

    jumpPage(name) {
      this.$router.push({ name });
    },
    openAdmin() {

      let openUrl="http://opencom.com.cn:40008/#/login";
      const currentUrl = window.location.href;
      let type = localStorage.getItem("type") || 2;
      if(currentUrl.includes("localhost")||currentUrl.includes("127.0.0.1")){
        openUrl = "http://" + window.location.hostname + ":40008/";
        let token = localStorage.getItem("token") || "";
        console.log("token:"+token);
        if(token && token.length>0){
          openUrl = openUrl + "?token=" + token;
        }
        if(type && type.length>0){
          openUrl = openUrl + "&type=" + type;
        }
        window.open(openUrl, "_self");
      }
      else if(currentUrl.includes("opencom")) {
        openUrl = "http://opencom.com.cn:40008/#/login";
        let token = localStorage.getItem("token") || "";
        if(token && token.length>0){
          openUrl = openUrl + "?token=" + token;
        }
        if(type && type.length>0){
          openUrl = openUrl + "&type=" + type;
        }
        window.open(openUrl, "_self");
      }
      else if(currentUrl.includes("openhis")) {
        openUrl = "http://openhis.org.cn:40009/#/login";
        let token = localStorage.getItem("token") || "";
        if(token && token.length>0){
          openUrl = openUrl + "?token=" + token;
        }
        if(type && type.length>0){
          openUrl = openUrl + "&type=" + type;
        }
        window.open(openUrl, "_self");
      }

    },

    onAnchor(id) {
      if (this.$route.name !== "home" && id !== "tail") {
        this.$router.push({
          path: `/new/home#${id}`,
        });
      } else {
        console.log(document.location, this.$route);
        document.location.href = `${document.location.origin}/#${this.$route.path}#${id}`;
      }
    },

    onSelect(item) {
      console.log("click item:"+item);
      const currentUrl = window.location.href;
      if(currentUrl.includes("localhost")){
        if(item==1){
          localStorage.setItem("type", 1);
          window.location.reload();
        }
        else if(item==2){
          localStorage.setItem("type", 2);
          window.location.reload();
        }
      }
      console.log(currentUrl);
      var  openUrl = "";
      if (currentUrl.includes("openhis")) {
        if(item==2){
          openUrl ="http://opencom.com.cn/#/new/home";
          let token = localStorage.getItem("token") || "";
          console.log("token2:"+token);
          if(token && token.length>0){
            openUrl = openUrl + "?token=" + token;
          }
           window.open(openUrl, "_self");
        }
      }
      else if(currentUrl.includes("opencom")) {
        if(item==1){
          openUrl ="http://openhis.org.cn/#/new/home";
          let token = localStorage.getItem("token") || "";
          console.log("token1:"+token);
          if(token && token.length>0){
            openUrl = openUrl + "?token=" + token;
          }
          window.open(openUrl,"_self");
        }
      }
      else {
        if(item==1){
          localStorage.setItem("type", 1);
          window.location.reload();
        }
        else if(item==2){
          localStorage.setItem("type", 2);
          window.location.reload();
        }
      }
    },
  },

  created() {

    // if(localStorage.getItem("type") == null){
    //   localStorage.setItem("type", 2);//默认为开源企业
    // }

    if(this.localStorageType==1){
      document.title = "开源医疗";
    }
    else if(this.localStorageType==2){
      document.title = "开源企业";
    }
  },

  computed: {
    ...mapGetters("user", ["getUserInfo"]),
  },
};
</script>

<style scoped lang="scss">
.header {
  position: sticky;
  top: 0;
  z-index: 20;
  padding: 0 40px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;

  &-logo {
    display: flex;
    align-items: center;

    img {
      height: 37px;
      margin-right: 8px;
    }
  }

  .el-button {
    border: none;
  }

  .el-dropdown-link {
    margin-right: 8px;
    cursor: pointer;
  }
}
</style>
