<template>
  <div id="tail" class="tail">

    <div class="tail-top" style="height: 370px">
      <template v-if="localStorageType==1">
        <p class="tail-title">从这里开始 开启医疗新生态</p>
      </template>
      <template v-else-if="localStorageType==2">
        <p class="tail-title">从这里开始 构建企业应用</p>
      </template>

      <div class="tail-btn">
        <el-button type="primary" style="width: 216px; height: 40px" @click="onAnchor('tail')">
          联系我们
        </el-button>
        <br><br>
        <p style="font-size:medium;color: white">武汉新致数字科技有限公司为新致软件实控子公司，成立于2017年。<br><br>
          新致新源（武汉新致新源软件有限公司）是新致软件旗下开源平台品牌，秉持“开源、共享、众包”的理念，致力于践行开源精神，打造应用管理软件开源生态。<br><br>
          作为中国应用管理软件开源的引领者，新致新源于2022年6月发布开源医疗软件平台OpenHIS.org.cn，于2023年6月发布开源企业软件平台OpenCOM.com.cn。</p>
      </div>
    </div>
    <div class="tail-bottom">
      <div class="tail-bottom-left" style="text-align: center">


        <template v-if="localStorageType==1">
          <img class="logo" src="@/assets/kyyl.png" alt="" />
        </template>
        <template v-else-if="localStorageType==2">
          <img class="logo" src="@/assets/kyqy.png" alt="" />
        </template>

        <p>版权所有 © 武汉新致数字科技有限公司</p>
        <p>版权所有 © 武汉新致新源软件有限公司</p>
        <template v-if="localStorageType==1">
<!--          <img class="qrcode" src="@/assets/qrcode.png" alt=""/>
          <img class="qrcode" src="@/assets/siwei.png" alt=""/>-->
          <img class="qrcode" src="@/assets/openhis-weixinGroup.png" alt=""/>
        </template>
        <template v-else-if="localStorageType==2">
<!--          <img class="qrcode" src="@/assets/qrcode.png" alt=""/>
          <img class="qrcode" src="@/assets/luowen.png" alt=""/>-->
          <img class="qrcode" src="@/assets/opencom-weixinGroup.png" alt=""/>
        </template>
        <p>欢迎扫码关注公众号获取产品及活动信息</p>

      </div>
      <div class="tail-bottom-right">
        <div class="tail-bottom-right-left">
          <p class="cell">关于我们</p>
          <div class="center">
            <p class="cell gray" @click="onClickToDoc('https://doc.weixin.qq.com/doc/w3_ARsAQwZCAAkSOV1bTDGQ7ycxsBEMj')" style="cursor:pointer">常见问题</p>
<!--            <p class="cell gray" @click="onAnchor('case')" style="cursor:pointer">行业案例</p>-->
            <p class="cell gray" @click="onClickToNew('/userAgreement')" style="cursor:pointer">使用条款</p>
            <p class="cell gray" @click="onClickToNew('/privacyPolicy')" style="cursor:pointer">隐私政策</p>
<!--            <p class="cell gray" @click="onAnchor('news')" style="cursor:pointer">行业资讯</p>-->
<!--            <p class="cell gray" :type="$route.name.includes('community') ? 'primary' : ''"-->
<!--               @click="onClick('community')" style="cursor:pointer">开源社区</p>-->
          </div>
          <p class="cell">友情链接</p>
        </div>
        <div class="tail-bottom-right-right">
          <p class="cell">联系我们</p>
          <div class="center">
            <template v-if="localStorageType==1">
<!--              <p class="cell gray">联系电话 13886097253 (彭先生)</p>-->
              <p class="cell gray">联系电话 18192021605 (田先生)</p>
<!--              <p class="cell gray">联系电话 17317360616 (徐先生)</p>-->
              <p class="cell gray">联系邮箱 openhis@newtouch.com</p>
              <p class="cell gray">
                公司地址 湖北省武汉市花城大道8号花山软件新城二期B10栋<!--401-->
              </p>
            </template>
            <template v-if="localStorageType==2">
<!--              <p class="cell gray">联系电话 13708206115(罗先生)</p>-->
              <p class="cell gray">联系电话 13708206115（罗先生）</p>
              <p class="cell gray">联系邮箱 openhis@newtouch.com</p>
              <p class="cell gray">
                公司地址 湖北省武汉市花城大道8号花山软件新城二期B10栋<!--401-->
              </p>
            </template>
          </div>
          <p class="cell gray">
            <span><a href="https://talent.tntlinking.com/" target="_blank" style="color: gray">天天数链</a></span>
            <span><a href="https://www.newtouch.com/" target="_blank" style="color: gray">新致软件</a></span>
            <template v-if="localStorageType==1">
<!--              <span><a href="http://chisc.net/" target="_blank" style="color: gray">医盟网</a></span>
              <span><a href="http://www.qyiliao.com/" target="_blank" style="color: gray">Q医疗</a></span>-->
            </template>
            <template v-else-if="localStorageType==2">

            </template>
          </p>
        </div>
      </div>
    </div>
    <div class="tail-record">
      Copyright©2023 武汉新致数字科技有限公司 保留所有权利 鄂ICP备2022011542号
      鄂公网安备 33011002015175号
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      localStorageType: localStorage.getItem("type") || 2,
    };
  },
  methods: {
    onAnchor (id) {
      if (this.$route.name !== "home" && id !== "tail") {
        this.$router.push({
          path: `/new/home#${id}`,
        });
      } else {
        console.log(document.location, this.$route);
        document.location.href = `${document.location.origin}/#${this.$route.path}#${id}`;
      }
    },
    onClickToNew (val){
      // 获取目标页面的完整 URL
      const targetUrl = this.$router.resolve({ path: val }).href;
      // 在新标签页中打开目标页面
      window.open(targetUrl, '_blank');
    },
    onClickToDoc(val){
      window.open(val, '_blank');
    }
  },
};
</script>

<style scoped lang="scss">
.tail {
  &-top {
    height: 280px;
    background: #161616;
  }

  &-title {
    font-size: 48px;
    color: #ffffff;
    line-height: 35px;
    text-align: center;
    padding-top: 90px;
  }

  &-btn {
    text-align: center;
    margin-top: 45px;
  }

  &-bottom {
    height: 480px;
    background: #20232a;
    display: flex;
    justify-content: center;
    align-items: center;

    &-left {
      margin-right: 114px;

      .logo {
        width: 208px;
      }

      .qrcode {
        width: 135px;
        height: 135px;
        //margin-right: 25px;
      }

      p {
        font-size: 17px;
        color: #ffffff;
        line-height: 35px;
        padding: 0px 0;
      }
    }

    &-right {
      display: flex;

      &-left {
        width: 164px;
      }

      .center {
        margin: 40px 0;

        p + p {
          margin-top: 30px;
        }
      }

      .cell {
        font-size: 20px;
        color: #ffffff;

        span + span {
          margin-left: 80px;
        }
      }

      .gray {
        color: #797b7f;
        font-size: 17px;
      }
    }
  }

  &-record {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px 0;
    background: #20232a;
    font-size: 17px;
    color: #797b7f;
    text-align: center;
  }
}

</style>
